<template>
    <content-wrapper>
        <questions-list
            editable
            searchable
            show-summary
            hide-author
            hide-date
            :payload="payload"
            @click:item="onClickItem"
        />
    </content-wrapper>
</template>

<script>
import QuestionsList from '@apps/questions/components/QuestionsList'
import ContentWrapper from '@/components/ContentWrapper'
export default {
    name: 'QuestionsTeacherPage',
    components: { ContentWrapper, QuestionsList },
    computed: {
        authUuid() {
            return this.$auth.user.uuid
        },
        payload() {
            return {
                author: this.authUuid
            }
        }
    },
    methods: {
        onClickItem(item) {
            this.$router.push({
                name: 'manage.teacher.questions.sets',
                params: { uuid: item.uuid }
            })
        }
    }
}
</script>

<style lang=scss>

</style>
